#customNav.owl-nav {
    margin-top: 15px;

    button {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-color: $white;
        border: 1px solid darken($light-gray, 5%);
        border-radius: 50%;

        &.owl-prev {
            margin-right: 10px
        }
    }
}

nav.nav-pages1 {
    text-align: center;

    >.row {
        margin: 10px 5px;
    }

    a,
    div.active {
        display: block;
        background-color: $dark-gray;
        border: 1px solid darken($dark-gray, 5%);
        padding: 10px 20px;
        margin: 0 5px;
        color: $light-gray;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: rem-calc(12);

        &.disabled {
            cursor: not-allowed;
            color: #444;
        }

    }
}

// Article Section - Single Pages
//==========================================================================================================================
section.article {
    @include breakpoint(medium) {
        max-width: 1340px;
        margin: auto;
        position: relative;
        margin-top: -80px;
        background: white;
        border-radius: 10px;
    }
}
a.tag {
	padding: 5px 8px;
	border-radius: 5px;
	background-color: $light-gray;
	font-weight: 300;
	color: black;
	text-transform: uppercase;
	font-size: rem-calc(11);
}

// Headers
//==========================================================================================================================
header.header {
    z-index: 0;
    @include linear-gradient(to left, $primary-color 0%, $secondary-color 100%);

    &[data-interchange] {
        background-position: center;
    }

    &.hidden,
    &.none {
        display: none;
    }

    &.home,
    &.page.artikler {
        @include header-height ($hh: 550px, $hhb:250px);
    }

    &.fullscreen {
        @include header-height ($hh:calc(100vh - #{$top-height}), $hhb: 150px);
    }

    .iframe-wrapper {
        background: $dark-gray url(/img/globals/loader3.gif) no-repeat center center;
        background-size: 100px 100px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
        overflow: hidden;
    }

    /* Make the iframe keep an aspect ratio, and
position it in the middle of its parent wrapper*/

    .iframe-wrapper iframe {
        width: 100vw;
        height: 56.25vw;
        /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh;
        /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .header--box {
        text-align: center;
        padding: 30px 20px;
        max-width: 800px;
        background-color: transparent;

        @include breakpoint(medium) {
            text-align: center;
            margin: 0 auto;
        }
    }

    .header--overlay {
        @include overlay($bc: $secondary-color, $c:white, $hover:none);
        //@include linear-gradient(135deg, $primary-color 0%,  $secondary-color 100%);
        opacity: 0.7
    }

    .header--skew {
        display: none;
        position: absolute;
        width: 130vw;
        left: -10vw;
        height: 10vw;
        background-color: $primary-color;
        border-top: 2px solid $white;
        border-radius: 80% 40%;
        bottom: 0;
        transform: rotateZ(-3deg) translateY(7vw);
    }

    h2.pretitle {
        margin-bottom: 5px;
        font-weight: 400;
        color: $white;

        @include breakpoint(large) {
            font-size: rem-calc(18);
        }
    }

    h1 {
        margin: 0;
        font-weight: 600;
        color: $white;
        font-size: rem-calc(32);

        @include breakpoint(large) {
            font-size: rem-calc(48);
        }
    }

    p.desc {
        margin-bottom: 0;
        color: rgba(white, 0.9);
        font-weight: 500;
    }

    h1+p {
        margin-top: 40px;
    }

    p.data,
    p.data a {
        font-size: rem-calc(12);
        text-transform: uppercase;
        color: rgba(white, 0.9);

        span {
            margin: 0 10px;
        }
    }

    a.scroll-down {
        display: none;
        position: absolute;
        bottom: 20px;
        left: 50%;
        color: $white;
        font-size: 22px;
        transform: translateX(-50%);
        z-index: 1000;
        @include transition();

        &:hover {
            @include transition();
            transform: translateX(-50%) translateY(10px);
        }
    }

    a.button {
        font-size: rem-calc(18);
        font-weight: 400;
        //@include box-shadow()
        //padding: 0.8em 1.6em;
        //border-radius: 5px;
        @include button(false, $white, $primary-color, auto, hollow);
    }

    .breadcrumbs {
        display: inline-block;
        margin: 0;
    }
}

// Header Split Artikler
// ---------------------------------
header.header.header-split {
    overflow: visible;
    @include linear-gradient(to left, $primary-color 0%, $secondary-color 100%);
    @include header-height ($hh: 100px, $hhb:auto);

    .header-box {
        padding: 80px 0;
    }

    figure {
        position: absolute;
        margin-top: -80px;

        img {
            @include box-shadow();
        }

        figcaption {
            color: rgba($white, 0.6)
        }
    }
}

// Top
//==========================================================================================================================
section.top {
    @include box-shadow(0, 10px, 10px, 0);

    &.is-stuck {
        background-color: rgba($white, 0.8);
        @include box-shadow(0, 0, 50px);
    }

    a.button {
        @include button(false, $primary-color, $primary-color, auto, hollow);
        font-family: $nav-family;
        font-size: $nav-font-size;
        font-weight: $nav-font-weight;
        margin: 0;

    }
}

// Default Section Colors
//==========================================================================================================================

.bg-white-boxed {
    @include bg-boxed ();
    @include box-shadow($h: 0px, $v:-20px, $b:100px, $c:rgba($black, 0.1));
    background: $white;
}

.bg-light-gray-boxed {
    background-color: $light-gray;
    @include bg-boxed ();
}

.bg-light-gray {
    background-color: $light-gray;
    border-bottom: 1px solid mix(black, $light-gray, 3);
}

.bg-white {
    background-color: $white;
}

.bg-medium-gray {
    background-color: $medium-gray;
    @include color-invert();
}

.bg-dark-gray {
    background-color: $dark-gray;
    @include color-invert();
}

.bg-primary {
    background-color: $primary-color;
    @include color-invert();
}

.bg-secondary {
    background-color: $secondary-color;
    @include color-invert();
}

.bg-gradient {
    @include linear-gradient(to left, $primary-color 0%, $secondary-color 100%) @include color-invert();
}


// Hero
// ================================================================
// Use above and outside ROW class. Ex: <div class="hero-img large-6" style="background-image: url('/img/theme/trucks.jpg')"></div>

.hero {
    position: relative;

    >.row {
        @include breakpoint (large) {
            min-height: 500px;
        }
    }
}

.hero-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: $light-gray;
    background-size: cover;
    background-position: 50% 50%;

    @include breakpoint (small only) {
        position: relative;
        min-height: 300px;
    }

    &.hero-img-right {
        right: 0;
    }

    &.hero-img-left {
        left: 0;
    }

    &.focus-right {
        background-position: 100% 50%;
    }
}

.hero-body-right {
    @include breakpoint (medium) {
        padding-left: 30px;
    }
}

.hero-body-left {
    @include breakpoint (medium) {
        padding-right: 30px;
    }
}

// C2A Sections
//-----------------------------------------------------

section.call-to-action {
    p:last-child {
        margin: 0;
        font-size: 1.2em;
    }
}

// Shadows
//-----------------------------------------------------

.shadow {
    @include shadow($img: shadow-in, $o:0.8);
}



// Ports-1 - On index page
// ================================================================

.ports-1 {
    text-align: center;

    .fa {
        margin-bottom: 15px;
    }

    .card {
        background-color: $white;
        border-bottom: 2px solid darken($light-gray, 5%);
        margin-bottom: 0;

    }

    .card-section {
        padding: 20px;

        @include breakpoint(medium) {
            padding: 50px;
            border-right: 2px solid darken($light-gray, 5%);
        }
    }
}

// Overlay together with responsive-embed
// ================================================================

a.responsive-embed {
    display: block;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        @include transition();
    }

    div.overlay {
        @include overlay($bc: black, $c:white, $hover:fade);
    }
}

a:hover.responsive-embed {
    img {
        transform: scale(1.2) translateX(-50%) translateY(-50%);
        @include transition();
    }

    div.overlay {
        opacity: 0.8;
        width: 100%;
        top: 0;
    }
}

// Card Designs
// ================================================================

.card.miras-employees {
    text-align: center;
    background-color: #222222;
    @include color(#ccc, white, $primary-color);
    border-radius: 10px;
    border-bottom: 2px solid $primary-color;

    a {
        font-weight: 400;
    }
}

.card .card-image {
    position: relative;
}

// Used on Departments Miras
.card-bigbox {
    background: $light-gray;

    .card-section {
        padding: 3em 1em;
        border-top: 15px solid white;
    }
}

.card.white {
    background-color: white;
    text-align: center;
}

.card.article {
    background-color: $white;
    border-radius: 4px;
    border: 1px solid darken($light-gray, 5%);
    @include box-shadow($h: 0, $v:5px, $b:15px, $s:0, $c:rgba(0, 0, 0, 0.05));

    .title a {
        color: $header-color;
    }

    p.desc {
        margin-bottom: 0;
        font-size: 0.9em;
        font-weight: 400;
    }

    .date {
        font-size: rem-calc(12);
        font-weight: $global-weight-bold;
        color: black;
    }

    .tags {
        font-size: rem-calc(11);
        font-weight: $global-weight-bold;
        color: black;

        a {
            color: black;
            //background: $light-gray;
            padding: 2px 4px;
            border: 1px solid $medium-gray;
            border-radius: 4px;
            text-transform: uppercase;
            margin-right: 5px;
        }
    }

    .label {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 2;

        &.video {
            background-color: $vimeo-color;
        }
    }
}

// Blog List - Blog with Sidebar
// ================================================================

.blog-with-sidebar {

    .content {
        padding: map-get($grid-column-gutter, small);

        @include breakpoint(large) {
            padding: map-get($grid-column-gutter, large);
        }

        >.row>.column {
            @include grid-column-gutter(15px);
        }
    }

    .sidebar {
        padding-top: map-get($grid-column-gutter, large);
        background: $light-gray;

        hr {
            border-color: darken($light-gray, 10%);
        }

        .sidebar-title {
            text-transform: uppercase;
            font-size: rem-calc(13);
            letter-spacing: 0.1em;
        }

        p {
            font-size: 0.9em;
            font-weight: 500;
        }

        .widget {
            padding: 0 20px;
            margin-bottom: 50px;
        }

        ul.tagpool {
            list-style: none;
            margin: 0;

            li {
                display: inline-block;
                margin: 0 5px 5px 0;
            }

            li a {
                color: black;
                font-weight: 500;
                text-transform: uppercase;
                font-size: rem-calc(11);
                padding: 2px 4px;
                border: 1px solid $medium-gray;
                border-radius: 3px;
            }
        }
    }
}

// Custom Titles
// ================================================================
.title-1 {
    p {
        text-transform: uppercase;
        margin-bottom: 3px;
        font-size: 0.85em;
        font-weight: 400;
    }

    #{$headers} {
        color: $primary-color;
    }

    #{$headers} {
        &:after {
            content: "";
            //position: absolute;
            margin: 20px 0;
            width: 100%;
            height: 1px;
            display: block;
            background-color: darken($light-gray, 5%);
        }
    }
}

.title-2 {
    p {
        text-transform: uppercase;
        margin-bottom: 3px;
        font-size: 0.85em;
        font-weight: 400;
    }

    #{$headers} {
        color: white;
    }

    #{$headers} {
        &:after {
            content: "";
            //position: absolute;
            margin: 20px 0;
            width: 20%;
            height: 1px;
            display: block;
            background-color: white;
        }
    }
}

//Typography
//-----------------------------------------------------
a {
    font-weight: 500;
}

a.facebook {
    color: $facebook-color;
}

.hand {
    font-family: 'Indie Flower';
}

h5 {
    color: $primary-color;
    text-transform: uppercase;
}

strong {
    color: $dark-gray;
}

.lead {
    color: $dark-gray;
    font-weight: 600;
    letter-spacing: 0.1em;
}

figure {
    margin-bottom: $global-margin;
}

.sidebar-border {
    @include breakpoint(large) {
        border-left: 1px solid $light-gray;
    }
}

ul.keyvalue {
    margin-left: 0;
    list-style: none;

    li {
        display: flex;
        align-items: start;
        border-bottom: 1px solid darken($light-gray, 5%);
        padding: 15px 0;

        >span:first-child {
            color: black;
            font-weight: $global-weight-bold;
            width: 50px;
        }

        >span:last-child {
            span {
                display: block;
                font-size: 0.8em;
                text-transform: uppercase;
                color: #bbb;
            }
        }
    }
}

// Section Client
//-----------------------------------------------------

section.clients {
    background: $light-gray;

    .row.logos {
        .column {
            background: white;
        }

        a {
            display: block;
            height: 150px;
            line-height: 150px;
            max-width: 30vw;
            margin: 0 auto;
        }

        .column {
            border-right: 1px solid $light-gray;
            border-bottom: 1px solid $light-gray;

            @include breakpoint(small only) {

                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(6),
                &:nth-child(8) {
                    border-right: none;
                }

                &:nth-child(7),
                &:nth-child(8) {
                    border-bottom: none;
                }
            }

            @include breakpoint(medium) {

                &:nth-child(4),
                &:nth-child(8) {
                    border-right: none;
                }

                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8) {
                    border-bottom: none;
                }
            }
        }
    }
}

// Galleries
//-----------------------------------------------------
.gallery-1 {
    img {
        background-color: white;
        padding: 4px;
        @include box-shadow();
    }
}

// Features
//-----------------------------------------------------

.features-type-1 {
    .feature {
        .feature--icon {
            display: block;
            width: 70px;
            float: left;
            height: 70px;
            background-color: $primary-color;
            border-radius: 3px;
            font-size: 25px;
            color: $white;
            line-height: 70px;
            text-align: center;
            margin-right: 20px;
            margin-top: 3px;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            transition: all 0.3s;
        }

        .feature--text {
            float: left;
            width: 60%;
            width: calc(100% - 94px);
        }
    }
}

.features-type-2 {
    .feature {
        .feature--icon {
            display: block;
            width: 45px;
            float: left;
            font-size: 25px;
            color: $primary-color;
            text-align: center;
            margin: 0 10px 0 0;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            transition: all 0.3s;
        }

        .feature--text {
            float: left;
            width: 60%;
            width: calc(100% - 55px);
        }
    }
}

.features-type-3 {
    .feature {
        text-align: center;

        .feature--icon {
            display: block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 50%;
            border: 1px solid $primary-color;
            font-size: 16px;
            margin: 0 auto 10px;
            color: $primary-color;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            transition: all 0.3s;
        }
    }
}

.features-type-4 {
    padding: (map-get($grid-column-gutter, small)) 0 0;

    @include breakpoint(large) {
        padding: (map-get($grid-column-gutter, large) + 20px) 0 0;
    }

    .feature {
        background-color: $white;
        padding: 2em;
        @include box-shadow();

        .feature--icon {
            display: none;
            font-size: 2em;
            color: $primary-color;
        }
    }
}

// Callouts
//-----------------------------------------------------

.callout {
    background-color: $light-gray;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left-color: $primary-color;

    p.title {
        font-size: rem-calc(18);
        font-weight: 400;
        margin-bottom: 0;
    }
}

.callout.light-gray {
    background-color: $light-gray;
    border: 0px solid $medium-gray;
}

// Forms
//-----------------------------------------------------

form.form {

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
        border: 1px solid darken($light-gray, 8%);
    }
}

// Image Styles
//-----------------------------------------------------

img.border {
    background-color: $white;
    padding: 2px;
    border: 1px solid $light-gray;
}

// Bottom
//-----------------------------------------------------

$box-margin: 3rem;

%list {
    list-style: none;
    margin-left: 0;
}

#bottom {
    .logo-bottom img {
        width: $logo-width;
        max-width: 65%;
        margin-bottom: $box-margin;
        opacity: 0.85;
    }

    h4 {
        margin-bottom: 10px;
    }

    hr {
        border-bottom: 1px solid rgba(white, 0.1);
    }

    p.description {
        margin-bottom: $box-margin;
    }

    ul.socials {
        margin-bottom: $box-margin;
        @extend %list;

        li {
            display: inline-block;
            margin-right: 20px;
        }
    }

    ul.resources {
        margin-bottom: $box-margin;
        @extend %list;

        li {
            margin-bottom: 0.75em;
        }
    }

    ul.get-in-touch {
        @extend %list;
        margin-left: 2rem;

        li {
            margin-bottom: 1.5em;
        }

        .icon {
            margin-right: 2rem;
        }
    }
}

// Checkbox
//================================================================

label.checkbox input {
    vertical-align: top;
    line-height: 1;
    padding: 0;
    margin-bottom: 10px;
    min-height: 25px;
    min-width: 25px;
}

// Pagenav
//================================================================

.pagenav a {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    top: 50vh;
    transform: translateY(-50%);
    color: $dark-gray;
    height: 10vh;
    width: 120px;
    font-size: rem-calc(12);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
    border: 1px solid $dark-gray;

    i {
        font-size: rem-calc(22);
    }

}

.pagenav.left a {
    left: 0;

    i {
        margin-right: 10px;
    }
}

.pagenav.right a {
    right: 0;

    i {
        margin-left: 10px;
    }
}

// Widgets
//-----------------------------------------------------
ul.article-data {
    list-style: none;
    margin-left: 0;
    font-size: $small-font-size;

    li {
        padding: 5px 0;
    }

    i.fal {
        margin-right: 10px;
    }

    a {
        font-weight: $global-weight-bold;
    }

    li.data-tag {}
}

i.space {
    margin-right: 10px;
}

.simple-breadcrumbs {
    margin: 30px;
    font-size: rem-calc(13);
    text-transform: uppercase;
    color: $medium-gray;

    span.splitter {
        margin: 0 20px;
    }
}

.markbox {
    position: relative;
    width: 65px;
    height: 65px;
    margin: -10px auto 0;
    border-radius: 50%;
    border: 3px solid $light-gray;
    background-color: white;
    overflow: hidden;

    @include breakpoint(large) {
        width: 100px;
        height: 100px;
        margin: -70px auto -30px;
    }
}

.layout-path {
    background-color: black;
    color: white;
    padding: 3px 6px;
    font-size: rem-calc(12);
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
}

.lazy-header {
    background-size: cover;
}

section.fullscreen {
    >.row {
        min-height: 100vh;
    }
}


ul.taxonomies {
    list-style: none;
    margin: 60px 0 30px;

    li {
        display: inline-block;
        background-color: $light-gray;
        text-transform: uppercase;
        margin-bottom: 3px;

        a {
            font-size: 11px;
            font-weight: 400;
            letter-spacing: 0.05em;
            color: $dark-gray;
            display: block;
            padding: 0.5em 1em;

            @include breakpoint(medium) {
                font-size: 14px;
            }
        }

        a.active {
            background-color: $dark-gray;
            color: white;
        }
    }
}

.owl-carousel.logos {
    a {
        display: block;
        text-align: center;

        img {
            height: 35px;
            width: auto;
            margin: 0 auto;
            -webkit-filter: grayscale(100%);
            /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            @include transition();

            @include breakpoint(medium) {
                height: 50px;
            }
        }

        &:hover img {
            -webkit-filter: none;
            filter: none;
            @include transition();
        }
    }
}

a.faq-link {
    display: block;
    text-transform: uppercase;
    font-size: rem-calc(13);
    margin-bottom: $global-margin;
}

ul.accordion {
    a.accordion-title {
        text-transform: uppercase;
        font-weight: $global-weight-bold;
        //letter-spacing: 0.05em;
    }

    a.accordion-title,
    div.accordion-content {
        border-left: none;
        border-right: none;
    }
}

ul.tabs {
    text-align: center;
    padding: 20px 0;
    box-shadow: 0px 40px 80px -20px rgba($light-gray, 1);

    a,
    a:hover {
        color: $primary-color
    }

    li {
        background: $light-gray;
        margin-bottom: 3px;
    }

    li.is-active a {
        font-weight: 700;
    }

    .tabs-title {
        float: none;
        display: inline-block;
    }
}

figcaption {
    padding: 0.5em 0 0.5em 1em;
    margin: 1em 0;
    font-style: italic;
}

.employees {
    .card {
        background-color: $light-gray;
        border: 1px solid $medium-gray;
    }

    img {
        width: 100px;
        height: 100px;
    }

    span.name {
        font-weight: $global-weight-bold;
        color: $header-color;
        font-size: rem-calc(16px);
        display: block;
    }

    span.jobtitle {
        text-transform: uppercase;
        font-size: rem-calc(12px);
        display: block;

        @include breakpoint(medium) {
            font-size: rem-calc(13px)
        }
    }

    span.phone,
    span.email {
        //text-transform: uppercase;
        font-size: rem-calc(15px);
        display: block;

        @include breakpoint(medium) {
            font-size: rem-calc(16);
            text-align: right;
        }
    }
}

p.divider {
    font-size: rem-calc(13);
    text-transform: uppercase;
    margin: 2rem 0;
    color: $dark-gray;

    &:after {
        content: "";
        display: block;
        margin-top: -0.75em;
        border-top: 2px solid $light-gray;
    }

    span {
        background-color: $white;
        padding-right: 20px;
    }
}



p.service-items-title {
    font-weight: 300;
    color: $header-color;
}

ul.service-items {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li a {
        display: block;
        background-color: $light-gray;
        border: 1px solid $light-gray;
        text-align: center;
        padding: 30px 40px;
        text-transform: uppercase;
        font-weight: $global-weight-bold;
        font-size: rem-calc(14);
        margin: 2px;

        &:hover {
            @include transition();
            border: 1px solid $medium-gray;
        }

    }
}

#befaring-time {
    display: none;

    &.visible {
        display: block;
    }

    hr {
        border-bottom-color: rgba(black, 0.1);
    }
}

form.botform {
    margin-top: 3em;
}

// Off-canvas
//-----------------------------------------------------
$color: #495057;
$color-active: $black;
$bgColor: $light-gray;
$borderColor: $white;

.off-canvas-menu {


    background: $bgColor;
    border: 5px solid white;
    z-index: 30;

    .off-canvas-menu--top {
        background-color: transparent;
        height: $top-height-small;
    }

    .off-canvas-menu--title {
        padding: $global-menu-padding;
        padding-top: 40px;
        font-family: $header-font-family;
        font-size: rem-calc(28);
        font-weight: $global-weight-bold;
        color: rgba($black, 0.2);
        text-transform: uppercase;
        letter-spacing: 0.2em;

    }

    span.burger-lines {
        @include hamburger($white, $white, $burger-width, $burger-size, $burger-weight, 3);
    }


    .accordion-menu {
        font-size: rem-calc(14);

        >li {
            border-bottom: 1px solid $borderColor;
        }

        li a {
            color: $color;
            text-transform: none;
            font-weight: $global-weight-bold;
            letter-spacing: 0.1em;


        }

        li li>a {
            text-transform: capitalize;

        }

        li.active>a {
            color: $color-active;
            font-weight: $global-weight-bold;
            background: $accordionmenu-item-background;
        }
    }
}

button.submenu-toggle {
    border-left: 1px solid $borderColor;
    border-radius: 0;

    &[aria-expanded="true"] {
        border-bottom: 1px solid $borderColor;
    }
}

// Botform Original
// ================================================================
.botform {
    background-color: $light-gray;
    display: none;
    overflow-y: auto;
    position: fixed;
    right: 0;
    z-index: 101;
    padding: 40px 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 5px 0 15px 10px rgba(0, 0, 0, 0.3);
    box-shadow: 5px 0 15px 10px rgba(0, 0, 0, 0.3);

    @include breakpoint(medium) {
        width: 80%;
    }

    @include breakpoint(large) {
        width: 50%;
    }

    .card {
        background-color: darken($light-gray, 5%);
    }

    .botform--close {
        position: absolute;
        background-color: transparent;
        height: 40px;
        line-height: 40px;
        width: 50px;
        display: block;
        right: 20px;
        top: 20px;
        border-radius: 3px;
        text-align: center;
        color: $primary-color;
        text-decoration: underline;
        cursor: pointer;
        z-index: 10;
        font-weight: 500;
        font-size: 0.85em;
        text-transform: uppercase;
    }

    .hr-botform {
        background-color: $light-gray;
        margin: 1em 0 1.5em 0;
    }

    .adress {
        display: none;
    }

    #{$headers} {
        color: $primary-color;
    }
}